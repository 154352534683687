/* unplugin-vue-components disabled */import { unref as _unref, isRef as _isRef, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import InputNumber from 'primevue/inputnumber';
export default {
  __name: 'MNumberInput',
  props: {
    fz: String,
    modelValue: {
      default: 0
    }
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var inputValue = ref(props.modelValue);
    watch(inputValue, function (value) {
      console.log('emti', value);
      emit('update:modelValue', value);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createBlock(_unref(InputNumber), {
        class: "m-number-input",
        showButtons: "",
        buttonLayout: "horizontal",
        incrementButtonIcon: "pi pi-plus",
        decrementButtonIcon: "pi pi-minus",
        min: 0,
        modelValue: _unref(inputValue),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(inputValue) ? inputValue.value = $event : null;
        }),
        style: _normalizeStyle({
          '--base-input-font-size': __props.fz || null
        })
      }, null, 8, ["modelValue", "style"]);
    };
  }
};